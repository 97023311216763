import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, OnInit, DestroyRef } from '@angular/core';
import { DropDownListComponent, FieldSettingsModel, FilteringEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { AbstractControl, FormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { finalize } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { EmitType } from '@syncfusion/ej2-base';
import { Query } from '@syncfusion/ej2-data';
import { LanguageService } from 'src/app/core/services/language.service';

import enLocale from 'src/locale/ej2-locale/en-US.json';
import arLocale from 'src/locale/ej2-locale/ar.json';
import heLocale from 'src/locale/ej2-locale/he.json';
import { L10n } from '@syncfusion/ej2-base';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
    @ViewChild('sample') public listObj: DropDownListComponent;
    @Input() fields: FieldSettingsModel = {text: 'text', value: 'id'};
    @Input() control: FormControl | AbstractControl | any;
    @Input() context: any;
    @Input() label: string;
    @Input() url: string = '';
    @Input() labelClass: string;
    @Input() data: { [p: string | number]: any }[] = [];
    @Input() placeholder: string = 'Select';
    @Input() value: string = '';
    @Input() class: string = '';
    @Input() allowFiltering: boolean = false;
    @Input() itemTemplate: TemplateRef<any>;
    @Input() valTemp: TemplateRef<any>;
    @Input() isLoading: boolean = false;
    @Input() public locale : string = '';
    public height: string = 'auto';

    @Output() valueChange: EventEmitter<string | number> = new EventEmitter();

    get isRequired(): boolean {
        if (!this.control?.validator) {
            return false;
        }
        const validator = this.control?.validator({} as AbstractControl);
        return !!(validator && validator?.required);
    }

    constructor(
        private apiService: ApiService,
        private destroyRef: DestroyRef,
        public languageService: LanguageService
    ) {
        this.locale = languageService.locale;
    }

    ngOnInit(): void {
        this.initDatasource();
        this.setLocale();
    }

    setLocale() {
        switch (this.locale) {
            case 'ar':
                L10n.load({ 'ar': arLocale });
                break;
            case 'he':
                L10n.load({ 'he': heLocale });
                break;
            default:
                L10n.load({ 'en-US': enLocale });
                break;
        }                
    }

    initDatasource(): void {
        if (this.url) {
            this.isLoading = true;
            this.apiService.request('get', this.apiService.pathUrl(this.url), {body: this.context})
                .pipe(takeUntilDestroyed(this.destroyRef),
                    finalize((): void => {
                        this.isLoading = false;
                    })
                )
                .subscribe(data => {
                    this.data = data;
                });
        }
    }

    public onChange(data: any): void {
        this.valueChange.emit(data.value);
    }

    public onFiltering: EmitType<FilteringEventArgs> = (e: FilteringEventArgs): void => {
        let query: Query = new Query();
        query = query.search(e.text, ['name', 'code'], 'contains', true);
        e.updateData(this.data, query);
    }

}
