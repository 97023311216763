import { Component, DestroyRef, Input, OnInit } from '@angular/core';
import { menuItems, supportNumber } from 'src/app/app.constants';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IMenuItems } from 'src/app/core/models/app.models';
import { AuthService } from 'src/app/modules/auth/services/auth.service';
import { StateService } from 'src/app/core/services/state.service';
import { UserService } from 'src/app/core/services/user.service';
import { EUserRole } from 'src/app/core/enums/user-role.enum';
import { IAdminBalance } from 'src/app/core/models/user.model';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss'],

})

export class NavBarComponent implements OnInit {
    @Input() public isOpen: boolean;

    protected readonly EUserRole = EUserRole;
    protected readonly Array: ArrayConstructor = Array;
    public menuItems: IMenuItems[] = menuItems;
    public supportNumber: string = supportNumber;
    public currentRoute: string;
    public limit: number = 3;
    public showHIdeToggle: boolean = true;
    public providerActionBtnName: string = $localize`Show More`;

    constructor(
        private router: Router,
        private destroyRef: DestroyRef,
        private authService: AuthService,
        public stateService: StateService,
        public userService: UserService
    ) {
        this.currentRoute = router.url;
    }

    public ngOnInit(): void {
        this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.currentRoute = this.router.url;
                this.refreshData();
            }
        });
    }

    showMore(): void {
        if (this.showHIdeToggle) {
            this.limit = (this.stateService.balance as IAdminBalance[]).length;
            this.showHIdeToggle = !this.showHIdeToggle;
        } else {
            this.limit = 3;
            this.showHIdeToggle = !this.showHIdeToggle;
        }
        this.providerActionBtnName = this.showHIdeToggle ? $localize`Show More` : $localize`Hide`;
    }

    public logOut(): void {
        this.authService.logOut();
    }

    private refreshData(): void {
        if (this.userService.user) {
            this.stateService.getBalance(this.userService.user).subscribe(balance => {
            console.log(balance);
            });
        }
    }
}
