<label [for]="label" class="label" [class]="labelClass">{{label}}</label>
<span class="err-msg-text fz-16" *ngIf="isRequired">*</span>
<div class="drop-down-block" 
     [ngClass]="{'mt-8': !!label}">
    <ejs-dropdownlist
        #sample
        [dataSource]='data'
        (change)='onChange($event)'
        [formControl]="control"
        [value]='value'
        [fields]='fields'
        class="ejs-dropdownlist"
        [placeholder]='placeholder'
        [popupHeight]='height'
        [allowFiltering]='allowFiltering'
        (filtering)='onFiltering($event)'
        [cssClass]="'e-popup-prevent-close ' + class + (control?.invalid && control?.touched ? ' input-error' : '')"
        [itemTemplate]="itemTemplate"
        [locale]='locale'
        [zIndex]="2000"
        [enablePersistence]="true"
    ></ejs-dropdownlist>
    <div class="drop-down-loading" *ngIf="isLoading">
        <app-locale-loading class="btn-loader-blue">        
        </app-locale-loading>
    </div>
</div>
