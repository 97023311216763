<div class="main-block" *ngIf="isLoginPage || !!userService.user" [ngClass]="{'mobile': deviceService.isMobile}">
    <div *ngIf="!isLoginPage" class="top-row-holder">
        <app-news *ngIf="!deviceService.isMobile && !isAdmin"></app-news>
        <div *ngIf="deviceService.isMobile" class="mobile-header">
            <div class="toggle-navbar" (click)="navbarToggle()">
                <img src="./assets/images/icons/menu-toggle-icon.png" alt="toggle">
            </div>
            <div>
                <img src="./assets/images/logo/logo.png" alt="logo">
            </div>
        </div>
    </div>
    <div class="main-row">

        <div class="main-grid">
            <div *ngIf="(!deviceService.isMobile || isOpen) && !isLoginPage" [ngClass]="{'left-col-opened': isOpen}"
                 class="left-col">
                <div class="left-col-grid-holder">
                    <div class="left-col-grid">
                        <div [ngClass]="{'minimized-nav-bar': !isOpen}" class="logo-row-holder">
                            <div *ngIf="isOpen">
                                <img src="./assets/images/logo/logo.png" alt="logo">
                            </div>
                            <div class="toggle-navbar" (click)="navbarToggle()">
                                <img src="./assets/images/icons/menu-toggle-icon.png" alt="toggle">
                            </div>
                        </div>

                        <div class="nav-row-holder">
                            <div class="scroll-holder">
                                <div class="scroll-container">
                                    <div class="nav-bar-section w-100">
                                        <app-nav-bar [isOpen]="isOpen"></app-nav-bar>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="lang-row-holder">
                            <app-language [isOpen]="isOpen"
                                          [selectedLanguage]="langService.selectedLanguage"></app-language>
                        </div>
                    </div>
                </div>
            </div>

            <div class="center-col">
                <div class="content-section">
                    <div class="scroll-holder">
                        <div class="scroll-container center-col-f-dir">
                            <app-news *ngIf="deviceService.isMobile && !isLoginPage"></app-news>
                            <div class="router-outlet">
                                <div class="justify-center"
                                     *ngIf="deviceService.isMobile && !isLoginPage && !isAdmin">
                                    <app-banner></app-banner>
                                </div>
                                <router-outlet></router-outlet>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="right-col {{isLoginPage ? 'login-banner-bg' : ''}}"
                 *ngIf="!deviceService.isMobile && (!isAdmin || isLoginPage) && stateService.showBanner">
                <app-banner></app-banner>
            </div>
        </div>
    </div>
</div>
<app-loading></app-loading>
