import { Component } from '@angular/core';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';
import { LanguageService } from 'src/app/core/services/language.service';
import { DeviceService } from 'src/app/core/services/device.service';
import { NavigationEnd, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UserService } from 'src/app/core/services/user.service';
import { StateService } from 'src/app/core/services/state.service';
import { UserModel } from 'src/app/core/models/user.model';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent {
    public isOpen: boolean = true;
    public isLoginPage: boolean = false;
    public isAdmin: boolean = true;

    constructor(
        public deviceService: DeviceService,
        public langService: LanguageService,
        public userService: UserService,
        public stateService: StateService,
        private router: Router
    ) {
        userService.currentUser$.subscribe((user: UserModel): void => {
            this.isAdmin = user?.isAdmin;
        });
        this.isOpen = this.isOpen = JSON.parse(localStorage.getItem('menuStatus'));

        router.events.pipe(takeUntilDestroyed()).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.isLoginPage = ['', '/', '/auth', '/auth/set-password'].includes(this.router.url);
            }
            if (deviceService.isMobile) {
                this.isOpen = false;
                document.getElementsByClassName('center-col')[0]?.classList.remove('no-scroll');
                document.body.classList.add('mobile');
            }
        });
        this.setLanguage();
    }

    setLanguage(): void {
        const language: string = localStorage.getItem('language');
        of(language).pipe(take(1)).subscribe((): void => {
            if (language && this.langService.selectedLanguage &&
                this.langService.selectedLanguage !== language && !this.deviceService.isLocal) {
                window.location.href = '/' + language;
            }
        });
    }

    public navbarToggle(): void {
        this.isOpen = !this.isOpen;
        localStorage.setItem('menuStatus', String(this.isOpen));
        if (this.deviceService.isMobile && this.isOpen) {
            document.getElementsByClassName('center-col')[0].classList.add('no-scroll');
        } else {
            document.getElementsByClassName('center-col')[0].classList.remove('no-scroll');
        }
    }
}
