import { Component, OnInit } from '@angular/core';
import { supportContact } from 'src/app/app.constants';
import { OtpCheckService } from 'src/app/core/services/otp-check.service';
import { UserService } from 'src/app/core/services/user.service';
import { StateService } from 'src/app/core/services/state.service';
import { FormControl } from '@angular/forms';
import { UserModel } from 'src/app/core/models/user.model';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { ToastComponent, ToastUtility } from '@syncfusion/ej2-angular-notifications';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
    selector: 'app-otp-check',
    templateUrl: './otp-check.component.html',
    styleUrls: ['./otp-check.component.scss']
})
export class OtpCheckComponent implements OnInit {
    protected readonly supportContact = supportContact;
    public otpCode: FormControl = new FormControl<any>(null);
    public toastObj?: ToastComponent;
    private toastTimeout: number = 5000;

    constructor(
        private otpCheckService: OtpCheckService,
        private userService: UserService,
        private stateService: StateService,
        private languageService: LanguageService,
        private router: Router
    ) {
        this.stateService.showBanner = false;
    }

    ngOnInit(): void {
       
        this.otpCheckService.sendOTP(this.userService.user.id)
            .subscribe({
                next: (response) => {
                    console.log('OTP sent successfully');
                },
                error: (error) => {
                    console.error('Failed to send OTP:', error);
                    this.toastObj = ToastUtility.show({
                        content: $localize`Failed to send OTP. Please try again.`,
                        timeOut: this.toastTimeout,
                        icon: 'warning-icon',
                        position: {X: this.languageService.selectedLanguage === 'en' ? 'Right' : 'Left', Y: 'Top'},
                        showCloseButton: true,
                        cssClass: 'e-toast-error'
                    }) as ToastComponent;
                }
            });
    }
    public verifyOTP(): void {
        this.otpCheckService.verifyRoute({id: this.userService.user.id, otp: this.otpCode.value})
            .pipe(catchError((): any => {
                this.toastObj = ToastUtility.show(
                    {
                        content: $localize`One-time password is incorrect or is expired`,
                        timeOut: 20000,
                        icon: 'warning-icon',
                        position: {X: this.languageService.selectedLanguage === 'en' ? 'Right' : 'Left', Y: 'Top'},
                        showCloseButton: true,
                        cssClass: 'e-toast-warning'
                    }) as ToastComponent;
            }))
            .subscribe((data: UserModel): void => {
                sessionStorage.setItem('otp_token', JSON.stringify(data.token));
                this.router.navigate(['reports']);
            });
    }
}
